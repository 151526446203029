import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Auth from "../../libs/auth";
import swal from "sweetalert";

axios.defaults.withCredentials = true;
const SERVER = process.env.API_URL + "/admin/customers";
const CUSTOMER = process.env.API_URL + "/admin/tax_year";

const START_YEAR = 2016;
const CURRENT_YEAR = new Date().getFullYear();
const END_YEAR = CURRENT_YEAR + 3;
const YEARS = Array.from(
  { length: END_YEAR - START_YEAR + 1 },
  (_, i) => START_YEAR + i
);

class PlanDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: props.storeData.current_customer,
      isLoading: true,
      customerinfo: [],
      plans: [],
      userEmail: "",
      message: null,
      subscription_stats: [],
      year: null,
      providers: [],
      selectedProvider: "admins",
      transactionId: null,
      subscribedYears: [],
      submitSubscription: false
    };
  }

  componentDidMount() {
    this.fetchSubscriptionStat();
  }
  fetchSubscriptionStat = () => {
    let config = {
      headers: Auth.getHeader()
    };
    axios
      .get(`${CUSTOMER}/${this.state.customerId}/plan_detail`, config)
      .then(response => {
        if (response.data.status === "success") {
          const alreadySubscribedYears = response.data.subscriptions.map(
            subscription => subscription.year
          );
          const nonSubscribedYears = this.getYears(alreadySubscribedYears);
          let midYear =
            nonSubscribedYears[Math.round((nonSubscribedYears.length - 1) / 2)]; // get the middle year
          this.setState({
            subscription_stats: response.data.subscriptions,
            subscribedYears: alreadySubscribedYears,
            isLoading: false,
            year: midYear,
            providers: response.data.providers,
            submitSubscription: false,
            plans: response.data.plans
          });
        } else {
          this.setState({
            isLoading: false,
            year: this.currentYear(),
            providers: response.data.providers
          });
        }
      });
  };

  handleBlockAccess = (e, id) => {
    let config = {
      headers: Auth.getHeader()
    };
    let data = {
      id: id,
      active: e.target.value
    };
    axios
      .post(`${CUSTOMER}/toggle_subscription_status`, data, config)
      .then(response => {
        if (response.data.status == "success") {
          this.fetchSubscriptionStat();
        }
      })
      .catch(err => {
        Auth.isUnauthorized(err.response.status);
      });
  };

  handleYearUpdate = (e, id) => {
    let config = {
      headers: Auth.getHeader()
    };
    let data = {
      id: id,
      year: e.target.value
    };
    axios
      .post(`${CUSTOMER}/update_subscription_year`, data, config)
      .then(response => {
        if (response.data.status == "success") {
          this.fetchSubscriptionStat();
        }
      })
      .catch(err => {
        Auth.isUnauthorized(err.response.status);
      });
  };

  createSubscription = () => {
    this.setState({ submitSubscription: true });
    const {
      year,
      customerId,
      subscription_stats,
      transactionId,
      selectedProvider
    } = this.state;
    let config = {
      headers: Auth.getHeader()
    };

    if (subscription_stats.length === 0 && transactionId === null) {
      this.setState({
        submitSubscription: false,
        transactionId: ""
      });
      swal("Please enter Payment ID");
      return;
    }

    const data = {
      year: year,
      transaction_id: transactionId,
      provider: selectedProvider
    };
    axios
      .post(`${SERVER}/${customerId}/subscriptions/`, data, config)
      .then(response => {
        if (response.data.status === "success") {
          this.fetchSubscriptionStat();
          this.setState({ transactionId: "" });
        }
      })
      .catch(err => {
        swal("Oops!", err.response.data.message, "error");
        this.setState({
          submitSubscription: false,
          transactionId: ""
        });
        Auth.isUnauthorized(err.response.status);
      });
  };

  handlePlanUpdate = (e, id) => {
    const { customerId } = this.state;
    let config = {
      headers: Auth.getHeader()
    };
    let data = {
      plan_id: e.target.value
    };
    axios
      .patch(
        `${SERVER}/${customerId}/subscriptions/${id}/update_plans`,
        data,
        config
      )
      .then(response => {
        if (response.data.status == "success") {
          this.fetchSubscriptionStat();
        }
      })
      .catch(err => {
        Auth.isUnauthorized(err.response.status);
      });
  };

  renderOptions = (options, selectedValue) => {
    return options.map((item, idx) => {
      const index = typeof item === "object" ? item.id : item;
      const label = typeof item === "object" ? item.name : item;
      return (
        <option key={idx} selected={index === selectedValue} value={index}>
          {this.capitalizeFirstLetter(label)}
        </option>
      );
    });
  };

  currentYear = () => {
    const currentYear = new Date().getFullYear();
    return currentYear - 1;
  };

  setYear = e => {
    this.setState({ year: e.target.value });
  };

  getYears = years => {
    const { subscribedYears } = this.state;
    const arr = years || subscribedYears;
    return YEARS.filter(year => !arr.includes(year));
  };

  capitalizeFirstLetter = string => {
    return typeof string !== "string"
      ? string
      : string.charAt(0).toUpperCase() + string.slice(1);
  };

  transactionPlaceholder = () => {
    const { subscription_stats, selectedProvider } = this.state;
    if (
      ["stripe", "bitpay"].includes(selectedProvider) ||
      subscription_stats.length === 0
    ) {
      return "Required";
    } else {
      return "Optional";
    }
  };

  render() {
    const {
      isLoading,
      message,
      subscription_stats,
      plans,
      year,
      submitSubscription,
      transactionId,
      providers,
      selectedProvider
    } = this.state;
    let customerinfo = this.state.customerinfo[0];
    return (
      <div className="animated fadeIn">
        {isLoading ? (
          <div className="preloader loader-small" />
        ) : (
          <div>
            <div className="card">
              <div className="card-header">
                <i className="fas fa-edit" /> Plan
                <div className="card-actions">
                  <a href="http://zenledger.io">
                    <small className="text-muted" />
                  </a>
                </div>
              </div>
              <div className="row col-sm-12 mt-3">
                <div className="col-sm-11">
                  <h5>{_.get(customerinfo, "email")}</h5>
                </div>
                <div className="col-sm-1">
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => this.props.showTaxDetail()}
                  >
                    Back
                  </button>
                </div>
                <div className="col-sm-12">
                  <b>
                    Note: For plan upgrade case, check all boxes for that year
                    to block access.
                  </b>
                </div>
              </div>
              <br />
              <div className="">
                {message && (
                  <div className="col-sm-12 mt-3">
                    <div className="alert alert-primary" role="alert">
                      {message}
                    </div>
                  </div>
                )}
                <div className="row col-sm-12">
                  <table className="table table striped">
                    <tr>
                      <th>Year</th>
                      <th>Plan</th>
                      <th>Block Access</th>
                    </tr>
                    <tr>
                      <td>
                        <select
                          className="form-control custom-select"
                          onChange={e => this.setYear(e)}
                        >
                          {this.renderOptions(this.getYears(), year)}
                        </select>
                      </td>
                      <td className="subscription-form-td">
                        <input
                          type="text"
                          className="form-control w-50 d-inline-block"
                          placeholder={`Payment ID (${this.transactionPlaceholder()})`}
                          value={transactionId}
                          onChange={e =>
                            this.setState({ transactionId: e.target.value })
                          }
                        />
                        <select
                          className="form-control custom-select ml-2 mt-n1"
                          onChange={e =>
                            this.setState({ selectedProvider: e.target.value })
                          }
                        >
                          {this.renderOptions(providers, selectedProvider)}
                        </select>
                        <small className="form-text text-muted">
                          Hint: When adding stripe id add customer id, which
                          starts with cus_*
                        </small>
                      </td>
                      <td>
                        <button
                          className="btn btn-link btn-sm"
                          onClick={this.createSubscription}
                          disabled={submitSubscription}
                        >
                          {submitSubscription ? "Submitting..." : "Add new"}
                        </button>
                      </td>
                    </tr>
                    {subscription_stats.length > 0 ? (
                      subscription_stats.map(sub => {
                        return (
                          sub.active != null && (
                            <tr key={sub.id}>
                              <td>
                                <select
                                  className="form-control custom-select"
                                  onChange={e =>
                                    this.handleYearUpdate(e, sub.id)
                                  }
                                >
                                  {this.renderOptions(YEARS, sub.year)}
                                </select>
                              </td>
                              <td>
                                <select
                                  className="form-control custom-select"
                                  onChange={e =>
                                    this.handlePlanUpdate(e, sub.id)
                                  }
                                >
                                  {this.renderOptions(plans, sub.plan_id)}
                                </select>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    value={sub.active === false ? true : false}
                                    checked={
                                      sub.active === false ? true : false
                                    }
                                    onChange={e =>
                                      this.handleBlockAccess(e, sub.id)
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          )
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="3">Subscriptions not found.</td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        <style jsx>{`
          .custom-select {
            width: 30%;
            min-width: 80px;
          }
          .subscription-form-td {
            max-width: 285px;
          }
        `}</style>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showTaxDetail: customer => {
      dispatch({
        type: "ENABLE_CL",
        payload: customer
      });
    }
  };
};

export default connect(null, mapDispatchToProps)(PlanDetail);
